






































import Pagination from '@/components/Pagination.vue';
import { Vue, Component } from 'vue-property-decorator';
import CopyToClipboard from 'vue-copy-to-clipboard'
import { getWithdrawals, GetWithdrawsResponse, setWithdrawalPaid } from '@/classes/TayehApi/Credit';
@Component({
    components: {
        Pagination,
        CopyToClipboard
    }
})
export default class Users extends Vue {
    page = 0;
    count = 0;
    search: string = null;
    withdrawals: GetWithdrawsResponse[] = [];
    paid_withdrawals = true;
    pageChange (page: number) {
        this.page = page;
        this.getWithdrawals();
    }

    async getWithdrawals () {
        const res = await getWithdrawals({paid: this.paid_withdrawals?1:0});
        this.count = res.data.count;
        this.withdrawals = res.data.withdrawals;
        // this.data = res.data;
    }

    async setPaid(id: string) {
        try {
            await setWithdrawalPaid(id)
            alert('paid')
        } catch (err) {
            console.log(err);
        }
    }

    mounted() {
        this.getWithdrawals()
    }
}
